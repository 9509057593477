import React from "react"

const Separator = ({ color = "gray-700", top = "", bottom = "" }) => {
  return (
    <div
      className={`block h-1 w-16 rounded-full bg-${color} mt-${top} mb-${bottom}`}
    ></div>
  )
}

export default Separator
