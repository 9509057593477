import React from "react"
import Layout from "../components/layout"
import SectionTitle from "../components/sectionTitle"
import SEO from "../components/seo"
import Separator from "../components/separator"
// import Img from "gatsby"
import RenderIf from "../components/renderIf"

const Block = ({ title, content, index, url, label }) => {
  const odd = (index + 1) % 2 !== 0
  return (
    <div className="mb-20 flex items-center">
      <RenderIf isTrue={!odd}>
        <div
          className="w-6/12 bg-gray-100 rounded-lg mr-12"
          style={{ height: 450 }}
        >
          {/* <Img></Img> */}
        </div>
      </RenderIf>
      <div className="flex-1">
        <SectionTitle>{title}</SectionTitle>
        <p className="mb-8 mt-4 leading-relaxed">{content}</p>
        <a href={url} className="link">
          {label}
        </a>
      </div>
      <RenderIf isTrue={odd}>
        <div
          className="w-6/12 bg-gray-100 rounded-lg ml-12"
          style={{ height: 450 }}
        >
          {/* <Img></Img> */}
        </div>
      </RenderIf>
    </div>
  )
}

const Features = ({ data }) => {
  const { items: features } = data.takeshape.getFeaturesList
  const { label, url } = data.takeshape.getHomePage.heroSection.callToAction
  return (
    <Layout>
      <SEO title="Features" />
      <div className="container mx-auto px-5 pt-20">
        {features.map(({ title, content }, index) => (
          <Block
            key={index}
            title={title}
            content={content}
            index={index}
            label={label}
            url={url}
          />
        ))}
      </div>
    </Layout>
  )
}

export default Features

export const pageQuery = graphql`
  {
    takeshape {
      getFeaturesList(sort: { field: "order", order: "asc" }) {
        total
        items {
          image {
            title
          }
          title
          content
          order
        }
      }
      getHomePage {
        heroSection {
          callToAction {
            label
            url
          }
        }
      }
    }
  }
`
